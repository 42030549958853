<template>
  <Toast></Toast>
  <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="surface-card p-4 shadow-4 border-round w-full lg:w-6" >
      <div class="text-center mb-5">
        <img src="images/logo.png" alt="Image" height="100" class="mb-3">
        <div class="text-900 text-3xl font-medium mb-3">Criação de Senha</div>
        <span class="text-600 font-medium line-height-3">Adicione sua senha para acesso ao ClinicalMind</span>
      </div>

      <div class="field">
        <label for="email4" :class="{'p-error': v$.userRequest.password.$error}">Senha *</label>
        <div class="p-inputgroup">
          <Password id="email4"
                    toggleMask
                    v-model="userRequest.password"
                    @blur="v$.userRequest.password.$touch"
                    autofocus :class="{'p-invalid': v$.userRequest.password.$error}"
          />
        </div>
        <small class="p-error mt1" v-for="error of v$.userRequest.password.$errors"
               :key="error.$uid">{{error.$message}}</small>
      </div>

      <div class="field ">
        <label for="password4" :class="{'p-error': v$.confirmPassword.$error}">Confirmar Senha *</label>
        <div class="p-inputgroup">
          <Password id="password4"
                    v-model="confirmPassword"
                    toggleMask
                    @blur="v$.confirmPassword.$touch"
                    autofocus :class="{'p-invalid': v$.confirmPassword.$error}"
          />
        </div>
        <small class="p-error mt1" v-for="error of v$.confirmPassword.$errors"
               :key="error.$uid">{{error.$message}}</small>
      </div>

      <Button label="Salvar" class="w-full py-3 mt-3 mb-5 font-medium" @click.prevent="updatePassword"></Button>
    </div>
  </div>
</template>

<script>

import LoginService from "@/service/LoginService";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "UpdatePassword",
  loginService: null,
  created() {
    this.loginService = new LoginService();
  },
  async mounted() {
    this.hash = this.$route.query.hash;
    if(this.hash) {
      const response = await this.loginService.validation(this.hash);
      if(response.status === 204) {
        await this.$router.push('pageNotFound')
      }
    }
  },
  data() {
    return {
      userRequest: {
        password: null,
        hash: null
      },
      hash: null,
      confirmPassword: null
    }
  },
  validations() {
    return {
      userRequest: {
        password: {
          required: helpers.withMessage('Campo Senha é obrigatório.', required)
        }
      },
      confirmPassword: {
        required: helpers.withMessage('Campo Confirmar Senha é obrigatório.', required)
      }
    }
  },
  methods: {

    async updatePassword() {
      const isFormValid = await this.v$.$validate();
      if(!isFormValid) {
        return;
      }
      if(this.userRequest.password === this.confirmPassword) {
        this.userRequest.hash = this.hash;
        const response = await this.loginService.updatePassword(this.userRequest);
        if (response.status === 200) {
          this.$router.push({name: 'home'});
        }
      } else {
        this.$toast.add({severity:'warn', summary: 'Alerta', detail: 'Senha diferente da confirmação.', life: 3000});
      }
    }
  }
}
</script>

<style scoped lang="scss">


</style>
